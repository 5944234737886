import type { Interpolation } from "@emotion/serialize";

import {
  BorderRadiusScale,
  ColorScheme,
  CSSValue,
  FocusRing,
  ResponsiveValue,
  SpaceScale,
  Theme,
} from "../theme";
import { Color, ColorPreset, ColorProp, CSSRulesFunction } from "../theme";
import { focusRingVal } from "../theme/cssvariables";
import { focusRingStyle } from "../theme/focusRings";

import {
  CardGradient,
  CardLayout,
  CardVariant,
  GradientConfig,
} from "./cardTypes";

const defaultGutter = 2;
const defaultBorderRadius = 1;
const defaultBgColor = Color.White;

const gradientColors: Record<CardGradient, Array<GradientConfig>> = {
  [CardGradient.Gradient01]: [
    {
      layout: CardLayout.Horizontal,
      degree: 91,
      colors: [
        {
          color: Color.Dusk_50,
          stop: 2.96,
        },
        {
          color: Color.Ultraviolet_50,
          stop: 98.77,
        },
      ],
    },
    {
      layout: CardLayout.Vertical,
      degree: 196,
      colors: [
        {
          color: Color.Dusk_50,
          stop: 11.27,
        },
        {
          color: Color.Ultraviolet_50,
          stop: 88.73,
        },
      ],
    },
  ],
  [CardGradient.Gradient02]: [
    {
      layout: CardLayout.Horizontal,
      degree: 354,
      colors: [
        {
          color: Color.Sunrise_50,
          stop: 5.96,
        },
        {
          color: Color.Sunset_50,
          stop: 58.77,
        },
      ],
    },
    {
      layout: CardLayout.Vertical,
      degree: 354,
      colors: [
        {
          color: Color.Sunrise_50,
          stop: 5.96,
        },
        {
          color: Color.Sunset_50,
          stop: 58.77,
        },
      ],
    },
  ],
};

export interface CardApperanceProps {
  width?: ResponsiveValue<number | string>;
  minWidth?: ResponsiveValue<number | string>;
  maxWidth?: ResponsiveValue<number | string>;
  backgroundColor?: ResponsiveValue<ColorProp>;
  spaceAbove?: ResponsiveValue<SpaceScale>;
  spaceBelow?: ResponsiveValue<SpaceScale>;
  spaceBefore?: ResponsiveValue<SpaceScale>;
  spaceAfter?: ResponsiveValue<SpaceScale>;
  borderRadius?: ResponsiveValue<CSSValue<BorderRadiusScale>>;
  gutterV?: ResponsiveValue<SpaceScale>;
  gutterH?: ResponsiveValue<SpaceScale>;
  variant?: CardVariant;
  layout?: CardLayout;
  bgGradient?: CardGradient;
}

export const cardStyle: CSSRulesFunction<{
  props: CardApperanceProps;
  isNarrow: boolean;
}> = (theme, { props, isNarrow }) => {
  const width = theme.responsive(props.width, (value) => ({
    width: value,
  }));

  const minWidth = theme.responsive(props.minWidth || 300, (value) => ({
    minWidth: value,
  }));

  const maxWidth = theme.responsive(props.maxWidth, (value) => ({
    maxWidth: value,
  }));

  const spaceAbove = theme.responsive(props.spaceAbove, (value, themeValue) => {
    return { marginTop: themeValue.spacing(value) };
  });

  const spaceBelow = theme.responsive(props.spaceBelow, (value, themeValue) => {
    return { marginBottom: themeValue.spacing(value) };
  });

  const spaceBefore = theme.responsive(
    props.spaceBefore,
    (value, themeValue) => {
      return { marginLeft: themeValue.spacing(value) };
    }
  );

  const spaceAfter = theme.responsive(props.spaceAfter, (value, themeValue) => {
    return { marginRight: themeValue.spacing(value) };
  });

  const borderRadius = theme.responsive(
    props.borderRadius ?? defaultBorderRadius,
    (r, themeValue) => ({
      borderRadius: themeValue.radius(r),
    })
  );

  const gutterV = theme.responsive(
    props.gutterV ?? defaultGutter,
    (value, themeValue) => {
      const spacingValue = themeValue.spacing(value);
      return { paddingTop: spacingValue, paddingBottom: spacingValue };
    }
  );

  const gutterH = theme.responsive(
    props.gutterH ?? defaultGutter,
    (value, themeValue) => {
      const spacingValue = themeValue.spacing(value);
      return { paddingLeft: spacingValue, paddingRight: spacingValue };
    }
  );

  const backgroundColor = theme.responsive(
    props.backgroundColor ?? defaultBgColor,
    (c) => ({
      backgroundColor: theme.color(c),
    })
  );

  const borderColor =
    props.variant === CardVariant.Outlined
      ? theme.color(ColorPreset.BorderOnLight_01)
      : theme.color(ColorPreset.BorderOnLight_04);

  return [
    gradientStyle(theme, { props, isNarrow }),
    {
      width: "100%",
      textAlign: "left",
      border: `1px solid ${borderColor}`,
    },
    gutterH,
    width,
    minWidth,
    maxWidth,
    gutterV,
    backgroundColor,
    borderRadius,
    spaceAbove,
    spaceAfter,
    spaceBefore,
    spaceBelow,
  ];
};

export const cardButtonStyle: CSSRulesFunction<{
  props: CardApperanceProps;
  isNarrow: boolean;
}> = (theme, { props, isNarrow }) => {
  return [
    cardStyle(theme, { props, isNarrow }),
    gradientStyle(theme, { props, isNarrow }),
    {
      fontFamily: theme.tokens.fontFamilies["sans-serif"],
      fontSize: "inherit",
      lineHeight: "inherit",
      boxShadow: `inset 0 0 0 2px transparent, ${focusRingVal}`,
      "&:hover, &:active": {
        borderColor:
          props.variant === CardVariant.Outlined
            ? theme.color(ColorPreset.BorderOnLight_04)
            : theme.color(ColorPreset.BorderOnLight_01),
        cursor: "pointer",
        boxShadow: `inset 0 0 0 2px transparent, ${focusRingVal}`,
      },
    },
    focusRingStyle(theme, {
      variant: FocusRing.Heavy,
      colorScheme: ColorScheme.OnLight,
      focusSelector: "&:focus-visible",
    }),
  ];
};
export const mediaStyle: Interpolation = {
  display: "flex",
  alignItems: "flex-start",
  overflow: "hidden",
  img: {
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "scale-down",
    flexShrink: 1,
    height: "auto",
  },
};

// Custom alignment for button `text` variant
export const headerActionStyle: Interpolation = {
  marginTop: "-10px",
  marginRight: "-10px",
};

// Custom spacing with header for button `text` variant
export const mainContentStyle: Interpolation = {
  marginTop: "-4px",
};

const gradientStyle: CSSRulesFunction<{
  props: CardApperanceProps;
  isNarrow: boolean;
}> = (theme, { props, isNarrow }) => {
  if (props.variant !== CardVariant.Gradient) {
    return [];
  }

  const gradientConfig = gradientColors[
    props.bgGradient || CardGradient.Gradient01
  ].find(
    (gr) => gr.layout === (isNarrow ? CardLayout.Vertical : props.layout)
  )!;

  return [
    {
      background: generateGradientStyle(theme, gradientConfig),
    },
  ];
};

const generateGradientStyle = (
  theme: Theme,
  config: GradientConfig
): string => {
  const { degree, colors } = config;

  const colorStops = colors
    .map((stop) => `${theme.color(stop.color)} ${stop.stop}%`)
    .join(", ");

  return `linear-gradient(${degree}deg, ${colorStops})`;
};
