import * as React from "react";

import {
  AlignItems,
  JustifyContent,
  JustifyItems,
  TypePreset,
  TypeScale,
  useTheme,
} from "../theme";
import { Text } from "../typography";

import XYGrid from "./XYGrid";
import Box from "./Box";
import {
  generateHorizontalGridAreas,
  generateHorizontalTemplateColumns,
  generateVerticalGridAreas,
  generateVerticalTemplateColumns,
  useIsNarrowCard,
} from "./cardUtils";
import {
  cardButtonStyle,
  cardStyle,
  CardApperanceProps,
  headerActionStyle,
  mainContentStyle,
  mediaStyle,
} from "./cardStyle";
import {
  CardArea,
  CardActionProps,
  CardButtonVariant,
  CardHeaderActionProps,
  CardLayout,
  CardType,
} from "./cardTypes";
import CardHeaderAction from "./CardHeaderAction";
import FooterAction from "./CardFooterAction";

type HTMLDivAttributes = Omit<
  React.HTMLAttributes<HTMLDivElement>,
  keyof CardApperanceProps
>;

type HTMLButtonAttributes = Omit<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  keyof CardApperanceProps
>;

interface CardContentProps extends CardApperanceProps {
  media?: React.ReactNode;
  header?: React.ReactNode;
  mainContent?: React.ReactNode;
  headerAction?: CardHeaderActionProps;
  primaryFooterAction?: CardActionProps;
  secondaryFooterAction?: CardActionProps;
}
interface CardBaseProps {
  cardType: CardType;
}

interface CardDivProps extends CardBaseProps, CardContentProps {
  cardType: CardType.Plain;
}
interface CardButtonProps extends CardBaseProps, CardContentProps {
  cardType: CardType.Button;
}

type CardStyleProps = CardDivProps | CardButtonProps;

export interface PlainCardProps extends CardDivProps, HTMLDivAttributes {}
export interface ButtonCardProps
  extends CardButtonProps,
    HTMLButtonAttributes {}

export type CardProps = PlainCardProps | ButtonCardProps;

const omitCardProps = <Props extends CardStyleProps>(
  props: Props
): Omit<Props, keyof CardStyleProps> => {
  const {
    header,
    mainContent,
    media,
    headerAction,
    primaryFooterAction,
    secondaryFooterAction,
    backgroundColor,
    borderRadius,
    bgGradient,
    gutterV,
    gutterH,
    variant,
    layout,
    width,
    minWidth,
    maxWidth,
    spaceAbove,
    spaceBelow,
    cardType,
    spaceBefore,
    spaceAfter,
    ...rest
  } = props;

  return rest;
};

const Card = React.forwardRef<HTMLDivElement | HTMLButtonElement, CardProps>(
  function Card(props, ref) {
    const { theme } = useTheme();
    const localRef = React.useRef<HTMLDivElement | HTMLButtonElement>(null);
    const {
      header,
      media,
      mainContent,
      headerAction,
      primaryFooterAction,
      secondaryFooterAction,
      cardType,
    } = props;

    const footerActions = [
      ...(primaryFooterAction ? [primaryFooterAction] : []),
      ...(secondaryFooterAction ? [secondaryFooterAction] : []),
    ];

    const hasAction = footerActions.length > 0;
    const isClickable = cardType === CardType.Button;
    const isNarrow = useIsNarrowCard(localRef);
    const cardLayout = isNarrow ? CardLayout.Vertical : props.layout;
    const isHorizontal = cardLayout === CardLayout.Horizontal;

    const areasToBeRemoved = Object.keys(CardArea)
      .map((key) => CardArea[key as keyof typeof CardArea])
      .filter((area) =>
        area !== CardArea.Empty && area !== CardArea.FooterActions
          ? !props[area]
          : false
      );

    if (isClickable) {
      areasToBeRemoved.push(
        CardArea.HeaderAction,
        CardArea.FooterActions,
        CardArea.Empty
      );
    } else if (
      areasToBeRemoved.includes(CardArea.HeaderAction) &&
      isHorizontal
    ) {
      areasToBeRemoved.push(CardArea.Empty);
    }

    if (!hasAction) {
      areasToBeRemoved.push(CardArea.FooterActions);
    }

    const getTemplateColumns = (layout?: CardLayout) =>
      layout === CardLayout.Horizontal
        ? generateHorizontalTemplateColumns(areasToBeRemoved)
        : generateVerticalTemplateColumns(areasToBeRemoved);

    const getTemplateAreas = (layout?: CardLayout) =>
      layout === CardLayout.Horizontal
        ? generateHorizontalGridAreas(areasToBeRemoved)
        : generateVerticalGridAreas(areasToBeRemoved);

    const cardContent = (
      <XYGrid
        templateAreas={getTemplateAreas(cardLayout)}
        templateColumns={getTemplateColumns(cardLayout)}
        columnGap={1.5}
      >
        {media && (
          <Box
            gridArea={CardArea.Media}
            spaceBelow={isHorizontal ? 0 : 1.5}
            css={mediaStyle}
          >
            {media}
          </Box>
        )}
        {!isClickable && headerAction && (
          <Box
            gridArea={CardArea.HeaderAction}
            layout="flex"
            justifyContent={JustifyContent.End}
            alignItems={AlignItems.FlexStart}
            css={
              headerAction.buttonVariant === CardButtonVariant.Text &&
              (media || header)
                ? headerActionStyle
                : {}
            }
          >
            <CardHeaderAction
              {...headerAction}
              variant={headerAction.buttonVariant}
            />
          </Box>
        )}

        {header && (
          <Box
            gridArea={CardArea.Header}
            layout="flex"
            flexDirection="column"
            justifyContent={
              isHorizontal ? JustifyContent.FlexStart : JustifyContent.Center
            }
            spaceBelow={mainContent ? 0.75 : 0}
          >
            <Text lineHeight={TypeScale.Size_02} preset={TypePreset.Heading_03}>
              {header}
            </Text>
          </Box>
        )}

        {mainContent && (
          <Box
            gridArea={CardArea.MainContent}
            layout="flex"
            flexDirection="column"
            css={
              isHorizontal &&
              headerAction &&
              headerAction.buttonVariant !== CardButtonVariant.Text &&
              header
                ? mainContentStyle
                : {}
            }
          >
            <Text preset={TypePreset.Body_01}>{mainContent}</Text>
          </Box>
        )}
        {!isClickable && hasAction && (
          <Box gridArea={CardArea.FooterActions} spaceAbove={1.25}>
            <XYGrid
              rowGap={0.5}
              columnGap={0.5}
              justifyItems={JustifyItems.Start}
              alignItems={AlignItems.Center}
              templateColumns={isNarrow ? "1fr" : "auto 1fr"}
            >
              {footerActions.map((fa, idx) => {
                return (
                  <FooterAction key={idx} value={fa} isNarrow={isNarrow} />
                );
              })}
            </XYGrid>
          </Box>
        )}
      </XYGrid>
    );

    // Merge the forwarded ref with the local ref
    React.useImperativeHandle(
      ref,
      () => localRef.current as HTMLDivElement | HTMLButtonElement
    );

    if (isClickable) {
      return (
        <button
          css={cardButtonStyle(theme, { props, isNarrow })}
          {...omitCardProps(props)}
          ref={localRef as React.Ref<HTMLButtonElement>}
        >
          {cardContent}
        </button>
      );
    } else {
      return (
        <div
          css={cardStyle(theme, { props, isNarrow })}
          {...omitCardProps(props)}
          ref={localRef as React.Ref<HTMLDivElement>}
        >
          {cardContent}
        </div>
      );
    }
  }
);

export default Card;
