import {
  CSSRulesFunction,
  useTheme,
  ResponsiveValue,
  SpaceScale,
  AlignItems,
  ColorProp,
} from "../theme";

import Space from "./Space";

type Layout =
  | "horizontal"
  | "horizontalReverse"
  | "vertical"
  | "verticalReverse";
type HTMLAttributes = Omit<
  React.HTMLAttributes<HTMLDivElement>,
  "color" | "children"
>;

const bound = (min: number, max: number, input: number) =>
  Math.max(min, Math.min(max, input));

export interface LegacyCardProps extends HTMLAttributes {
  width?: ResponsiveValue<string>;
  height?: ResponsiveValue<string>;
  subject: React.ReactNode;
  /**
   * A value between 0 and 1 that sets the _suggested_ proportion of the
   * available width to allocate to the card subject. The remaining area will be
   * allocated to the content.
   *
   * Read about flex-basis to understand why this value is a suggested amount
   */
  subjectBasis?: ResponsiveValue<number>;
  layout?: ResponsiveValue<Layout>;
  gutter?: ResponsiveValue<SpaceScale>;
  alignItems?: ResponsiveValue<AlignItems>;
  bg?: ResponsiveValue<ColorProp>;
  color?: ResponsiveValue<ColorProp>;
  footer?: React.ReactNode;
  children: React.ReactNode;
}

const direction: Record<
  Layout,
  "row" | "row-reverse" | "column" | "column-reverse"
> = {
  horizontal: "row",
  horizontalReverse: "row-reverse",
  vertical: "column",
  verticalReverse: "column-reverse",
};

const cardStyle: CSSRulesFunction<LegacyCardProps> = (theme, props) => [
  { display: "flex", flexWrap: "nowrap", position: "relative" },
  theme.responsive(props.layout, (l) => ({ flexDirection: direction[l] })),
  theme.responsive(props.width, (width) => ({ width })),
  theme.responsive(props.height, (height) => ({ height })),
  theme.responsive(props.alignItems, (alignItems) => ({ alignItems })),
  theme.responsive(props.bg, (colorProp) => ({
    backgroundColor: theme.color(colorProp),
  })),
  theme.responsive(props.color, (colorProp) => ({
    color: theme.color(colorProp),
  })),
];

const subjectStyle: CSSRulesFunction<LegacyCardProps> = (theme, props) => {
  return [
    { img: { display: "block" } },
    theme.responsive(props.subjectBasis, (b) => {
      const basis = bound(0, 1, b) * 100;
      return { flexBasis: `${basis}%` };
    }),
  ];
};

const contentStyle: CSSRulesFunction<LegacyCardProps> = (theme, props) => {
  return [
    {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column",
    },
    theme.responsive(props.subjectBasis, (b) => {
      const basis = bound(0, 1, 1 - b) * 100;
      return { flexBasis: `${basis}%` };
    }),
  ];
};

/**
 * @deprecated This component will be deprecated and removed in a later version.
 * Please use `<Card />` instead
 */
const LegacyCard: React.FC<LegacyCardProps> = (props) => {
  const { theme } = useTheme();
  const {
    width,
    height,
    subject,
    layout,
    gutter,
    subjectBasis,
    alignItems,
    color,
    bg,
    footer,
    ...rest
  } = props;
  return (
    <div css={cardStyle(theme, props)} role="group" {...rest}>
      <div css={subjectStyle(theme, props)}>{subject}</div>
      {subject && props.children ? (
        <Space layout="inline" v={props.gutter} h={props.gutter} />
      ) : null}
      <div css={contentStyle(theme, props)}>
        {props.children}
        {footer}
      </div>
    </div>
  );
};

LegacyCard.defaultProps = {
  width: "100%",
  layout: "vertical",
  gutter: 0.5,
  subjectBasis: 0.5,
};

export default LegacyCard;
